<template>
  <div>
    <list
      ref="refAreaList"
      sort-by="AreaId"
      :refetch-records="fetchAreas"
      refetch-records-name="areas"
      :actions="actions"
      key-field="areaId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteArea"
      :filters.sync="filters"
    />
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import organizationChart from '@/services/organizationchart.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refAreaList = ref(null)
    /* Services */
    const {
      fetchAreas,
      deleteArea,
    } = organizationChart()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('organizationChart.area.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('organizationChart.area.columns.hierarchicalLevel'),
        key: 'nivelJerarquico.nombre',
      },
      {
        label: i18n.t('organizationChart.area.columns.order'),
        key: 'orden',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Area',
        routeName: 'apps-organization-chart-area-edit',
        params: ['areaId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Area',
        params: ['areaId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-organization-chart-area-add',
        label: i18n.t('Routes.AreaAdd'),
        aclAction: 'create',
        aclResource: 'Area',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refAreaList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchAreas,
      deleteArea,
    }
  },
}
</script>
